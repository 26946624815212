import {
  IApiContentResponseModel,
  IApiMediaWithCropsResponseModel,
} from '@ingeniorforeningen/umbraco-client';
import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';
import Layout from '@/layouts/Layout';
import { UmbracoGrid } from '@ingeniorforeningen/figurine-cms';
import { TBreadcrumbsProps } from '@ingeniorforeningen/figurine-core';
import { nextPathToUrl, urlToNextPath } from '@/lib/utilities';
import { getAllPages, getPageData } from '../../lib/apiHelpers';

const Slug = ({
  data: { page, settings, breadcrumbs },
}: {
  data: {
    page: IApiContentResponseModel & { image: IApiMediaWithCropsResponseModel };
    settings: IApiContentResponseModel;
    breadcrumbs: TBreadcrumbsProps['items'];
  };
}) => (
  <Layout page={page} settings={settings} breadcrumbs={breadcrumbs}>
    <UmbracoGrid data={page.properties?.components} />
  </Layout>
);

export async function getStaticPaths({
  preview,
  locales,
}: {
  preview: boolean;
  locales: string[];
}) {
  const allPages = await getAllPages({ locales, preview, take: 80 });

  const paths = allPages
    .map((p) => ({
      locale: p.locale,
      params: { slug: urlToNextPath(p?.route?.path || '/', p.locale) },
    }))
    .filter((p) => p.params.slug.length > 0) // filter out empty paths
    .filter((p) => p.params.slug.length < 2); // filter out non root paths

  if (paths.length < 1) {
    console.warn('No pages found. This should not happen');
  } else {
    console.log(`generating html pages for: ${paths.map((p) => `(${p.locale}:${p.params.slug})`)}`);
  }

  return {
    paths,
    fallback: 'blocking',
  };
}

export async function getStaticProps({
  params,
  preview,
  locale,
}: {
  params: Params;
  preview: boolean;
  locale: string;
}) {
  const path = nextPathToUrl(params.slug);
  const umbracoData = await getPageData({ path, locale, preview });

  return {
    props: {
      data: umbracoData,
      preview: preview || null,
    },
    revalidate: 60,
    notFound: umbracoData.page === null,
  };
}

export default Slug;
